window.addEventListener('DOMContentLoaded', () => {
    // load css
    const styleLinks = document.querySelectorAll('link[media="print"]');
    styleLinks.forEach(link => { link.setAttribute('media', 'all'); });

    // smooth scrolling on click of link
    const smoothScrollingLinks = [].slice.call(document.body.querySelectorAll('a[href*="#"]:not([href="#"]):not(#portfolio a)'));
    smoothScrollingLinks.forEach(anchor => {
        anchor.addEventListener('click', e => {
            e.preventDefault();

            const anchorToScrollTo = document.body.querySelector(anchor.getAttribute('href'));

            anchorToScrollTo.scrollIntoView({ behavior: 'smooth' });
        });
    });

    // down arrow
    const arrowElem = document.getElementById('arrowDown');
    const hrefToScrollTo = document.getElementById('location');

    arrowElem.addEventListener('click', e => {
        e.preventDefault();

        hrefToScrollTo.scrollIntoView({ behavior: 'smooth' });
    })

    // enable SimpleLightbox gallery for services
    new SimpleLightbox('#services a', { fileExt: 'png|jpg|jpeg|webp|avif' })

    // Navbar shrink function
    const navbarShrink = () => {
        const navbarCollapsible = document.body.querySelector('#mainNav');
        if (!navbarCollapsible) return;

        window.scrollY === 0
            ? navbarCollapsible.classList.remove('navbar-shrink')
            : navbarCollapsible.classList.add('navbar-shrink');
    };

    // Shrink the navbar 
    navbarShrink();

    // Shrink the navbar when page is scrolled
    document.addEventListener('scroll', navbarShrink, { passive: true });

    const header = document.getElementById('header');

    window.addEventListener('scroll', () => {
        header.getBoundingClientRect().top > -100
            ? mainNav.classList.remove("navbar-scrolled")
            : mainNav.classList.add("navbar-scrolled");
    }, { passive: true });

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector('.navbar-toggler');
    const responsiveNavItems = [].slice.call(document.querySelectorAll('#navbarResponsive .nav-link'));
    responsiveNavItems.map(responsiveNavItem => {
        responsiveNavItem.addEventListener('click', () => {
            if (window.getComputedStyle(navbarToggler).display !== 'none') {
                navbarToggler.click();
            }
        });
    });

    // Add phone number
    const phone = window.atob('MDc3Njg0MjIxMDU=')
    const phoneElem = document.getElementById('phone');
    phoneElem.setAttribute('href', `tel:${phone}`);
    phoneElem.textContent = `Phone: ${phone}`;

    // Add email
    const email = window.atob('cGF0QGtlbmlsd29ydGhlc3RhdGVzLmNvbQ==')
    const emailElem = document.getElementById('email');
    emailElem.setAttribute('href', `mailto:${email}`);
    emailElem.textContent = `Email: ${email}`;
});